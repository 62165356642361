import { instance } from './axios';
import Auth from '@aws-amplify/auth';
let url = process.env.REACT_APP_API_URL;

export const getCognitoToken = async () => {
  const token = await Auth.currentSession();
  return token.getIdToken().getJwtToken();
};

export const cardholderService = {
  createCardholder: async (membershipType) => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await instance
      .post(`${url}/v1/cardholders`, membershipType, config)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },

  assignCard: async (data) => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await instance
      .post(`${url}/v1/cardholders/assign-card`, data, config)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },

  verifyCard: async (lastFour, embossCode) => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await instance
      .get(
        `${url}/v1/cardholders/verify-card?lastFour=${lastFour}&embossCode=${embossCode}`,
        config
      )
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },
  verifyClientId: async (clientId) => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await instance
      .get(
        `${url}/v1/cardholders/verify-client-id?clientId=${clientId}`,
        config
      )
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },

  assignCard: async (data) => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    };
    const response = await instance
      .post(`${url}/v1/cardholders/assign-card`, data, config)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },

  fundCards: async (data) => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    };
    const response = await instance
      .post(`${url}/v1/cardholders/funding-request`, data, config)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },
};
