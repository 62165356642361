import '@aws-amplify/ui-react/styles.css';
import {
  Authenticator,
  useAuthenticator,
  View,
  Image,
  Heading,
  useTheme,
} from '@aws-amplify/ui-react';
import mocafi_logo from '../../../images/mocafi_logo.png';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

export const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Amplify logo" width={96} height={96} src={mocafi_logo} />
          <Heading level={3}>EXPRESS FUNDS</Heading>
          <Heading level={7}>Enabling fast and easy payments on the go</Heading>
        </View>
      );
    },
  };

  return <Authenticator hideSignUp={true} components={components} />;
};
