import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "react-toastify/dist/ReactToastify.css";
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

const withSwRegistration = (WrappedComp) => {
  return () => {
    // holds all the SW registration setup
    const [appUpdatePending, setAppUpdatePending] = useState(false);
    const location = useLocation();
    // updates the state when a new update is pending.
    const onSWUpdate = () => {
      setAppUpdatePending(!appUpdatePending);
    };
    // action for updating the service worker.
    const updateAction = () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // send the skip message to kick off the service worker install.
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
            // add an listener to reload page when the new service worker is ready.
            registration.waiting.addEventListener("statechange", (event) => {
              const { state = "" } = event.target || {};
              if (state === "activated") {
                window.location.reload();
              }
            });
          }
        });
      }
    };
    // effect added from router location to check for a new service worker on every
    // page transition (change of route).
    useEffect(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update();
        });
      }
    }, [location]);

    // registers the service worker based on config setting.
    if (process.env.REACT_APP_REGISTER_SW == "true") {
      serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    } else {
      serviceWorkerRegistration.unregister();
    }
    return (
      <WrappedComp
        updateAction={updateAction}
        appUpdatePending={appUpdatePending}
      />
    );
  };
};

const AppWithSwRegistration = withSwRegistration(App);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <BrowserRouter>
        <AppWithSwRegistration />
      </BrowserRouter>
    </Authenticator.Provider>
  </React.StrictMode>
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
