import { zipcodeRgex, phoneRegexp, lastFourRegexp } from '../helpers/regex'
import * as yup from 'yup'

// File that contains the validation schemas for all forms

export const PaymentSchema = (t) => {
  return yup.object().shape({
    householdId: yup.string().required('Required'),
    clientId: yup.string().required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    birthDate: yup.string().required('Required'),
    state: yup.string(),
    email: yup.string().email().optional(),
    phone: yup.string().optional('').matches(phoneRegexp, {
      message:
        'Mobile phone must have 10 digits,  no hyphens or other characters',
      excludeEmptyString: true,
    }),
    street: yup.string().max(50),
    city: yup.string(),
    apt: yup.string().max(25),
    zipcode: yup.string().notRequired().matches(zipcodeRgex, {
      message: 'Zip code must have 5 digits',
      excludeEmptyString: true,
    }),
  })
}

export const HouseHoldDetailSchema = (t) => {
  return yup.object().shape({
    householdId: yup.string().required('Required'),
  })
}

export const handleCardSchema = (t) => {
  return yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().required('Required'),
    phone: yup.string().required('Required'),
    state: yup.string().required('Required'),
    city: yup.string().required('Required'),
    zipcode: yup.string().required('Required'),
    // dob: yup.string().required('Required'),
    cardLast4: yup.string().required('Required').matches(lastFourRegexp, {
      message: 'Insert last 4 digits,  numbers, no hyphens or other characters',
      excludeEmptyString: true,
    }),
    allocatedAmount: yup.string().required('Required'),
    uniqueCode: yup
      .string()
      .required('Required')
      .min(5, 'Insert valid code')
      .max(5, 'Insert valid code'),
  })
}

export const FundSchema = (t) => {
  return yup.object().shape({
    cardLast4: yup.string().required('Required').matches(lastFourRegexp, {
      message: 'Insert last 4 digits, numbers, no hyphens or other characters',
      excludeEmptyString: true,
    }),
    uniqueCode: yup
      .string()
      .min(5, 'Insert a valid code')
      .max(5, 'Insert a valid code')
      .required('Required'),
    allocatedAmount: yup
      .number()
      .min(0, 'Value must be positive')
      .required('Required'),
    // municipalProgramId: yup.string().required('Required'),
  })
}

export const loginSchema = (t) => {
  return yup.object().shape({
    username: yup.string().required('Required'),
    password: yup.string().required('Required'),
  })
}
