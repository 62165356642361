import { useState, useEffect } from 'react';
import { Input } from '../../common/Input/Input';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Header from '../Header/Header';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { cardholderService } from '../../../services/cardholderService';
import { municipalityService } from '../../../services/municipalityService';
import plusIcon from '../../../images/plus.svg';
import minusIcon from '../../../images/minus.svg';
import bigCheck from '../../../images/bigCheck.svg';
import alertImage from '../../../images/alert.svg';
import hexIcon from '../../../images/hex.svg';
import unselectedCheckbox from '../../../images/unselectedCheckbox.svg';
import selectedCheckbox from '../../../images/selectedCheckbox.svg';
import Auth from '@aws-amplify/auth';
import { notify } from '../../../assets/helpers/toast';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { statesList } from "../../../assets/helpers/states"
import { cognitoAttibutes } from "../../../assets/enums/attributes"
import { WelcomePage } from "./WelcomePage"
import './MainPage.scss';


export const MainPage = () => {
  const [step, setStep] = useState(0);
  const [supplementalFunds, setSupplementalFunds] = useState(false);
  const [action, setAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const [assignedCard, setAssignedCard] = useState("");
  const [cards, setCards] = useState({
    number: 0,
    counter: 0,
    householdId: '',
    assignCardDto: [],
    assignedCards: [],
  });
  const [counter, setCounter] = useState({
    householdSize: 0,
    kidsUnderFive: 0,
    kidsAboveFive: 0,
    elegibleForSupplementalFunds: 0,
  });



  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    setError: setError2,
    reset: reset2,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(schemas.handleCardSchema()),
  });



  const verifyCard = (cardLast4, uniqueCode) => {
    return cardholderService
      .verifyCard(cardLast4, uniqueCode)
      .then((response) => {
        if (response.status === 200 && response?.data?.data?.isCardAvailable) {
          return true;
        } else {
          setError2('cardLast4', {
            type: 'manual',
            message: 'Invalid card or Code',
          });
          setError2('uniqueCode', {
            type: 'manual',
            message: 'Invalid card or Code',
          });
          return false;
        }
      })
      .catch((error) => { });
  };



  const fundCards = () => {

    let dataToPost = { ...assignedCard }

    if (!isLoading) {
      setIsLoading(true);
      cardholderService
        .fundCards(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            // notify("Card funded", 'success');
            setTimeout(() => {
              setIsLoading(false);
              // window?.location?.reload();
              setStep(step + 1)
            }, 2000);
          } else {
            notify(`${response.message}` || 'error', 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };


  const handleCard = handleSubmit2(async (data) => {

    const validCard = await verifyCard(data?.cardLast4, data?.uniqueCode);

    let asambleData = { ...data }

    delete asambleData?.street
    delete asambleData?.state
    delete asambleData?.zipcode
    delete asambleData?.city
    delete asambleData?.apt

    asambleData.address = {
      street: data?.street,
      apt: data?.apt,
      city: data?.city,
      state: data?.state,
      zipcode: data?.zipcode
    }

    const user = await Auth.currentAuthenticatedUser();
    let municipality = user.attributes[cognitoAttibutes?.municipality];

    asambleData.municipalProgramId = municipality
    asambleData.allocatedAmount = parseFloat(asambleData.allocatedAmount)

    let dataToPost = { ...asambleData }

    dataToPost.allocatedAmount = 0



    if (validCard && dataToPost) {
      setIsLoading(true);
      cardholderService
        .assignCard(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Cards assigned`, 'success');
            setAssignedCard(asambleData)
            setStep(step + 1);
            setIsLoading(false);
          } else {
            notify(`${response.message}` || 'error', 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });

    }
  });

  const backStep = () => {
    // Return to cards form if in thelast card
    if (cards?.counter === cards?.number) {
      setStep(1);
      preloadForm();
      setSupplementalFunds(false);
    } else {
      preloadForm();
    }
  };

  const preloadForm = () => {
    // Get last card
    const selectedCard = cards?.assignCardDto[cards?.counter - 1];

    // Preload form
    if (selectedCard) {
      setValue2('firstName', selectedCard?.firstName);
      setValue2('lastName', selectedCard?.lastName);
      setValue2('lastName', selectedCard?.age);
      // setValue2('dob', new Date(selectedCard?.dob + 'T00:00:00'));
      setValue2('cardLast4', selectedCard?.cardLast4);
      setValue2('uniqueCode', selectedCard?.uniqueCode);
      setSupplementalFunds(selectedCard?.isSupplementalElegible);
    }

    cards?.assignCardDto?.pop();
    // Fall back one card
    if (cards?.counter - 1 >= 0) {
      setCards((prevState) => {
        return {
          ...prevState,
          counter: cards?.counter - 1,
          assignCardDto: cards?.assignCardDto,
        };
      });
    } else {
      setCards((prevState) => {
        return {
          ...prevState,
          counter: 0,
          assignCardDto: [],
        };
      });
      reset2();
      setStep(0);
    }
  };

  const cancelFunding = () => {
    window?.location?.reload();

  };


  const handleSuplemental = (value) => {
    const newValue = !value;

    let supplementalCounter = 0;

    for (let i = 0; i < cards?.assignCardDto?.length; i++) {
      if (cards?.assignCardDto[i]?.isSupplementalElegible) {
        supplementalCounter = supplementalCounter + 1;
      }
    }

    if (newValue) {
      supplementalCounter = supplementalCounter + 1;
    }

    if (supplementalCounter > counter?.elegibleForSupplementalFunds) {
      notify(
        'You have exceeded the max number of supplemental funds eligible residents',
        'info'
      );
      return null;
    } else {
      setSupplementalFunds(newValue);
    }
  };

  const selectAction = (value) => {
    setAction(value);
  }

  const preloadData = () => {
    setValue2("firstName", "John")
    setValue2("lastName", "Doe")
    setValue2("phone", "1234567890")
    setValue2("email", "algo@test.com")
    setValue2("street", "My house")
    setValue2("apt", "91")
    setValue2("city", "Miami")
    setValue2("state", "FL")
    setValue2("zipcode", "33116")

  }


  useEffect(() => {
    // preloadData()
  }, []);

  return (
    <div>
      <LoadSpinner show={isLoading} />
      <Header />
      <div className="flex flex-col min-h-screen items-center bg-gray-100">
        {step === 0 && (
          <WelcomePage
            register={register2}
            errors={errors2}
            control={control2}
            cards={cards}
            back={backStep}
            step={step}
            nextStep={setStep}
            selectAction={selectAction}
            action={action}
          />
        )}
        {step === 1 && (
          <CardForm
            register={register2}
            errors={errors2}
            control={control2}
            cards={cards}
            back={backStep}
            step={step}
            cancelFunding={cancelFunding}
            handleSuplemental={handleSuplemental}
            supplementalFunds={supplementalFunds}
            action={handleCard}
          />
        )}
        {step === 2 && (
          <ConfirmationForm
            action={fundCards}
            back={backStep}
            cards={cards}
            step={step}
            acknowledge={acknowledge}
            setAcknowledge={setAcknowledge}
            cancelFunding={cancelFunding}
            assignedCard={assignedCard}
          />
        )}
        {step === 3 && <SuccessCard action={cancelFunding} />}
        {step === 4 && <ErrorCard action={cancelFunding} />}
      </div>
    </div>
  );
};

export const CardForm = (props) => {
  const {
    register,
    control,
    errors,
    action,
    back,
    step,
    cards,
    cancelFunding,
  } = props;
  return (
    <>
      <WizardSteps step={step} cards={cards} />
      <div className="flex justify-center">
        <div className="bg-white shadow-md py-4 rounded-md">
          <h3 className="text-center mt-2 mb-4 font-bold">
            Fund A Card
          </h3>
          <form className="form-container container">
            <div className="row">
              <h6>Enter the cardholders information below to place funds on a card</h6>
              <p style={{ color: "gray" }}>All fields marked with an asterisk (*) are required. </p>
              <div className="col-12">
                <div className="row">
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'First Name *'}
                    type={'text'}
                    name={'firstName'}
                    register={register}
                    placeholder={'Enter the first name'}
                    errors={errors?.firstName?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Last Name *'}
                    type={'text'}
                    name={'lastName'}
                    register={register}
                    placeholder={'Enter the last name'}
                    errors={errors?.lastName?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Phone Number *'}
                    type={'number'}
                    name={'phone'}
                    register={register}
                    placeholder={'Enter phone number'}
                    errors={errors?.phone?.message}
                    control={control}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Email Address *'}
                    type={'text'}
                    name={'email'}
                    register={register}
                    placeholder={'Enter email address'}
                    errors={errors?.email?.message}
                    control={control}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Street Address *'}
                    type={'text'}
                    name={'street'}
                    register={register}
                    placeholder={'Enter street address'}
                    errors={errors?.street?.message}
                    control={control}
                  />{' '}
                  <Input
                    bootstrap={'col-6'}
                    label={'Unit/Apt'}
                    type={'text'}
                    name={'apt'}
                    register={register}
                    placeholder={'Enter unit/apt'}
                    errors={errors?.apt?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-6'}
                    label={'State *'}
                    type={'select'}
                    options={statesList}
                    name={'state'}
                    register={register}
                    placeholder={'Enter state'}
                    errors={errors?.state?.message}
                    control={control}
                  />{' '}

                  <Input
                    bootstrap={'col-6'}
                    label={'City *'}
                    type={'text'}
                    name={'city'}
                    register={register}
                    placeholder={'Enter city'}
                    errors={errors?.city?.message}
                    control={control}
                  />{' '}
                  <Input
                    bootstrap={'col-6'}
                    label={'Zip Code *'}
                    type={'number'}
                    name={'zipcode'}
                    register={register}
                    placeholder={'Enter zipcode'}
                    errors={errors?.zipcode?.message}
                    control={control}
                  />{' '}

                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Last 4 card digits *'}
                    type={'text'}
                    name={'cardLast4'}
                    register={register}
                    placeholder={'Enter last 4 digits'}
                    errors={errors?.cardLast4?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Emboss Line 2 *'}
                    type={'text'}
                    name={'uniqueCode'}
                    register={register}
                    placeholder={'Enter emboss line 2 from the card'}
                    errors={errors?.uniqueCode?.message}
                    control={control}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Funding amount *'}
                    type={'number'}
                    name={'allocatedAmount'}
                    register={register}
                    placeholder={''}
                    errors={errors?.allocatedAmount?.message}
                    control={control}
                  />{' '}
                </div>
              </div>
            </div>
            <p
              className="cancel-funding-button"
              onClick={() => cancelFunding()}
            >
              Cancel Funding
            </p>
          </form>
          <div className="buttons-center-container">
            <AltButton action={() => back()} label={'Back'} />
            <ActionButton action={action} label={'Next'} />
          </div>
        </div>
      </div>
    </>
  );
};


export const HouseHoldCounter = (props) => {
  const { text, counter, name, handleCounter, disabled } = props;

  return (
    <div className="flex gap-x-4 my-4">
      <h6 className="text-lg font-semibold flex-1">{text}</h6>
      <div className="ml-auto flex gap-1 items-center">
        <img
          style={{ opacity: disabled ? 0.5 : 1 }}
          className="m-0 p-0 w-6 h-6 cursor-pointer"
          src={minusIcon}
          alt="minusIcon"
          onClick={
            disabled ? () => console.log('') : () => handleCounter(name, -1)
          }
        />
        <h6
          style={{ opacity: disabled ? 0.5 : 1 }}
          className="m-0 p-0 text-center"
        >
          {counter}
        </h6>
        <img
          style={{ opacity: disabled ? 0.5 : 1 }}
          className="m-0 p-0 w-6 h-6 cursor-pointer"
          src={plusIcon}
          alt="plusIcon"
          onClick={
            disabled ? () => console.log('') : () => handleCounter(name, +1)
          }
        />
      </div>
    </div>
  );
};

export const WizardSteps = (props) => {
  const { cards } = props;
  return (
    <div className="wizard-steps-container">
      <div>
        <p
          style={{ fontWeight: cards?.counter >= cards?.number ? 'bold' : '' }}
        >
          Card Funding
        </p>
        {cards?.counter >= cards?.number && (
          <img src={bigCheck} alt="bigCheck" />
        )}
      </div>
      <div>
        <p
          style={{ fontWeight: cards?.counter == cards?.number ? 'bold' : '' }}
        >
          Confirmation
        </p>
      </div>
    </div>
  );
};

export const ConfirmationForm = (props) => {
  const { action, step, cards, assignedCard, cancelFunding } =
    props;

  return (
    <>
      <WizardSteps step={step} cards={cards} />
      <div className="flex justify-center ">
        <div className="confirmation-screen bg-white shadow-md py-4 rounded-md">
          <div className="container">
            <h3 style={{ textAlign: 'center' }}>Confirmation</h3>
            {/* <h6>
              <b>Household ID:</b> {cards?.householdId}
            </h6> */}
            <div className="form-container">
              <div className="household-container">
                {assignedCard &&
                  <div className="household-cards-container">
                    <CardComponent card={assignedCard} />
                  </div>
                }

                <div style={{ marginTop: "48px" }} className="buttons-center-container">
                  <ActionButton action={() => action()} label={'Submit'} />
                </div>
              </div>
              <p
                className="cancel-funding-button"
                onClick={() => cancelFunding()}
              >
                Cancel Funding
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SuccessCard = (props) => {
  const { action } = props;

  return (
    <>
      <br />
      <div className="success-message-container container bg-white shadow-md py-4 rounded-md">
        <img src={bigCheck} alt="bigCheck" />
        <h3 style={{ textAlign: 'center' }}>
          Card funding request  <br />submitted <br />
        </h3>
        <br />
        <div className="buttons-center-container">
          <ActionButton action={() => action()} label={'Fund new cards'} />
        </div>
      </div>
    </>);
};

export const ErrorCard = (props) => {
  const { action } = props;

  return (
    <div className="success-message-container container bg-white shadow-md py-4 rounded-md">
      <img src={alertImage} alt="alertImage" />
      <h3 style={{ textAlign: 'center' }}>
        Something went wrong <br />
      </h3>
      <h5>Some cards were not funded</h5>
      <br />
      <div className="buttons-center-container">
        <ActionButton
          // action={() => action()}
          label={'Try Again'}
        />
      </div>
    </div>
  );
};

export const CardComponent = (props) => {
  const { card } = props;
  return (
    <div className="household-card-component">
      <div className="household-card-icon-container">
        <img src={hexIcon} alt="hexIcon" />
        <span></span>
      </div>
      <h1>{card?.firstName + ' ' + card?.lastName}</h1>
      <h2>$ {card?.allocatedAmount}</h2>
      <div className="household-card-icon-container">
        <h4>Primary Card 1</h4>
        <span></span>
      </div>
    </div>
  );
};

export const SupplementalFunding = (props) => {
  const { supplementalFunds, handleSuplemental } = props;

  return (
    <div className="supplementalfunding-component">
      {supplementalFunds && (
        <img
          src={selectedCheckbox}
          alt="selectedCheckbox"
          onClick={() => handleSuplemental(supplementalFunds)}
        />
      )}
      {!supplementalFunds && (
        <img
          src={unselectedCheckbox}
          alt="unselectedCheckbox"
          onClick={() => handleSuplemental(supplementalFunds)}
        />
      )}
      <h6>Supplemental funds eligible</h6>
    </div>
  );
};
