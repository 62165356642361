import { useEffect, useState, useRef } from 'react';
import Auth from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { MainPage } from '../MainPage/MainPage';
import { notify } from '../../../assets/helpers/toast';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { cognitoAttibutes } from '../../../assets/enums/attributes';
import IdleTimer from 'react-idle-timer'


export const Page = (props) => {
  const idleTimerRef = useRef(null);
  const [municipality, setMunicipality] = useState('');
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const history = useNavigate();


  const getMunicipalities = async () => {
    const user = await Auth.currentAuthenticatedUser();
    let response = user.attributes[cognitoAttibutes?.municipality];
    let expressapp = user.attributes[cognitoAttibutes?.expressapp];

    if (expressapp !== "1") {
      notify('You are not authorized to access this application', 'error');
      signOut();
      history('/login');
    }
    setMunicipality(response);
  };

  const handleOnIdle = () => {
    // Handle session timeout logic here
    notify('Session timed out', 'info');
    signOut();
    history('/login');
  };

  useEffect(() => {
    getMunicipalities();
  }, []);

  return <>
    <IdleTimer
      ref={idleTimerRef}
      timeout={1000 * 60 * process.env.REACT_APP_SESSION_TIMEOUT} // 5 minutes
      onIdle={handleOnIdle}
      // onActive={handleOnActive}
      debounce={250}
    />
    <MainPage />
  </>;
};
