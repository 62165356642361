import React, { useState, useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { capitalize } from '../../../assets/helpers/funciones';
import './Input.scss';
registerLocale('en', en);

// EYE component that shows or hyde password
export const ShowPassword = (props) => {
  const { type, inputType, showPassword } = props;

  if (type === 'password' && inputType !== 'text') {
    return <ion-icon name="eye-outline" onClick={showPassword}></ion-icon>;
  }
  if (type === 'password' && inputType === 'text') {
    return <ion-icon name="eye-off-outline" onClick={showPassword}></ion-icon>;
  } else return null;
};

export const SelectInput = (props) => {
  const {
    onChange,
    bootstrap,
    label,
    name,
    register,
    options,
    errors,
    disabled,
  } = props;

  const { ref } = register(name);

  return (
    <div className={`input-component-container ${bootstrap}`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <select
          className="input-select"
          onChange={onChange}
          {...register(name, {
            onChange: onChange,
          })}
          name={name}
          disabled={disabled}
        >
          <option value=""></option>
          {options?.map((o, i) => (
            <option key={o?._id} value={o?._id}>
              {o?.name}
            </option>
          ))}
        </select>
      </div>

      <p>{errors}</p>
    </div>
  );
};

export const Input = (props) => {
  const {
    bootstrap,
    label,
    type,
    title,
    name,
    register,
    placeholder,
    options,
    errors,
    data,
    subLabel,
    control,
    onChange,
    disabled,
    min,
    max,
  } = props;

  const [inputType, setInputType] = useState(type);

  const showPassword = () => {
    inputType === 'password' ? setInputType('text') : setInputType('password');
  };

  let errorInput = 'i';
  if (errors) {
    errorInput = 'error-input';
  }

  if (title) {
    return (
      <>
        <div
          style={{ marginTop: '30px' }}
          className="filter-button-container"
        ></div>
        <h5 className="form-input-title">{title}</h5>
      </>
    );
  }

  if (type === 'checkbox') {
    return (
      <CheckboxInput
        subLabel={data?.subLabel || subLabel}
        bootstrap={bootstrap}
        label={label}
        name={name}
        register={register}
        errorInput={errorInput}
        errors={errors}
        onChange={onChange || data?.onChange}
      />
    );
  }

  if (type === 'select') {
    return (
      <SelectInput
        options={data?.options || options}
        bootstrap={bootstrap}
        label={label}
        type={type}
        name={name}
        register={register}
        placeholder={placeholder}
        errors={errors}
        disabled={disabled || data?.disabled}
        onChange={onChange || data?.onChange}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <textarea
              id={name}
              className={`${errorInput}`}
              type={inputType}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
            />
          </div>
        </div>

        <p>{errors}</p>
      </div>
    );
  }

  if (type === 'date') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  placeholderText={placeholder}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  locale="en"
                  autoComplete="off"
                  disabled={disabled || data?.disabled}
                />
              )}
            />
          </div>
        </div>
        <p>{errors}</p>
      </div>
    );
  }

  if (name) {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <input
              id={name}
              className={`${errorInput}`}
              type={inputType}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
              min={min || data?.min}
              max={max || data?.max}
            />
          </div>

          <ShowPassword
            type={type}
            inputType={inputType}
            showPassword={showPassword}
          />
        </div>

        <p>{errors}</p>
      </div>
    );
  } else {
    return <div className={`input-component-container ${bootstrap}`}></div>;
  }
};

export const SelectPerPage = (props) => {
  const { options, label, value, onChange } = props;
  return (
    <div className="select-per-page">
      <label htmlFor="label">{label}</label>
      <select name="" id="label" onChange={(e) => onChange(e)} value={value}>
        {options?.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Select = (props) => {
  const { options, label, value, onChange } = props;
  return (
    <div className="simple-select">
      <label htmlFor="label">{label}</label>
      <select name="" id="label" onChange={(e) => onChange(e)} value={value}>
        {options?.map((o) => (
          <option key={o?.name} value={o?._id}>
            {o?.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export const CheckboxInput = (props) => {
  const {
    bootstrap,
    label,
    subLabel,
    errorInput,
    name,
    register,
    errors,
    onChange,
  } = props;

  return (
    <div className={`input-component-container ${bootstrap}`}>
      {/* <label htmlFor={name}>{label}</label> */}
      <div className="checkbox-input-sublabel-container">
        <input
          id={name}
          className={`checkbox-input  ${errorInput}`}
          type={'checkbox'}
          name={name}
          onChange={onChange}
          {...register(name, {
            onChange: onChange,
          })}
          autoComplete="off"
        />
        <h6>{subLabel}</h6>
      </div>
      <p>{errors}</p>
    </div>
  );
};
