// import checkboxChecked from '../../../images/checkboxChecked.svg';
// import checkbox from '../../../images/checkbox.svg';
import { NavLink } from 'react-router-dom';
// import copyImage from '../../../images/copyImage.svg';
import './Buttons.scss';

export const LogoButton = (props) => {
  const { link, src } = props;
  return (
    <NavLink to={link}>
      <img className="logo" src={src} alt="logo" />
    </NavLink>
  );
};

export const Button = (props) => {
  const { button, action, selected } = props;

  let color = '';
  let fontColor = '';

  if (selected === button?.name) {
    color = '#465B7A';
    fontColor = '#fff';
  }

  return (
    <div
      style={{ backgroundColor: color, color: fontColor }}
      onClick={() => action()}
      className="service-button"
    >
      <h6>{button?.name}</h6>
    </div>
  );
};

// export const CheckBox = (props) => {
//   const { button, action } = props;

//   return (
//     <div className="checkbox-button">
//       {button.show ? (
//         <img onClick={() => action()} src={checkboxChecked} />
//       ) : (
//         <img onClick={() => action()} src={checkbox} />
//       )}
//       <h6>{button?.name}</h6>
//     </div>
//   );
// };

export const PlanComponent = (props) => {
  const { plan } = props;
  return (
    <div className="plan-component">
      <h1>
        Plan{' '}
        <span
          style={{ textTransform: 'capitalize' }}
          className={`plan-${plan}-color`}
        >
          {plan}
        </span>{' '}
      </h1>
    </div>
  );
};

export const FilterButton = (props) => {
  const { button, selected, selectButton } = props;

  let active = '';

  if (button.value === selected) {
    active = 'true';
  }

  return (
    <div
      className={`filter-button filter-button-${active}`}
      onClick={() => selectButton(button.value)}
    >
      {button?.image && <img src={button?.image} alt="image" />}
      <h5>{button?.name}</h5>
      {button.value === selected && (
        <div className="filter-button-active-bar"></div>
      )}
    </div>
  );
};

export const ActionButton = (props) => {
  const { label, action, disabled } = props;


  if (disabled) {
    return (
      <button
        style={{ backgroundColor: '#e7e7e7' }}
        className=" text-white w-fit min-w-20 px-12 rounded-3xl"
      >
        <h6 className="py-3 mb-0 text-md font-bold">{label}</h6>
      </button>
    )
  }

  return (
    <button
      onClick={action}
      className="bg-mocablue text-white w-fit min-w-20 px-12 rounded-3xl"
    >
      <h6 className="py-3 mb-0 text-md font-bold">{label}</h6>
    </button>
  );
};

export const AltButton = (props) => {
  const { label, action } = props;

  return (
    <button
      onClick={() => action()}
      className="bg-white text-mocablue border-1 border-mocablue w-fit min-w-60 px-12 rounded-3xl mx-3"
    >
      <h6 className="py-3 mb-0 text-md font-bold">{label}</h6>
    </button>
  );
};

export const TableActionButton = (props) => {
  const { label, action, src } = props;

  return (
    <div onClick={() => action()} className="table-action-button">
      <img src={src} alt="edit" />
    </div>
  );
};

// export const CopyButton = () => {
//   return (
//     <div className="copy-button-container">
//       <div className="copy-button">
//         <img src={copyImage} alt="copyImage" />
//         <h6>Copiar</h6>
//       </div>
//     </div>
//   );
// };

export const Breadcrumb = (props) => {
  const { links } = props;

  return (
    <div className="breadcrumbs">
      {links.map((l, i) => (
        <div>
          <NavLink to={l?.url}>
            {i === links.length - 1 ? <b>{l?.name}</b> : l?.name}
          </NavLink>
          {i < links.length - 1 && <span>/</span>}
        </div>
      ))}
    </div>
  );
};
