import BrowserInstall1 from '../../../images/BrowserInstall1.png';
import BrowserInstall2 from '../../../images/BrowserInstall2.png';
import { ActionButton } from '../../common/Buttons/Buttons';
import './BrowserDownload.scss';
import { useNavigate } from 'react-router-dom';

export const BrowserDownload = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row instructions-container">
        <h1>Browser Download Process</h1>
        <h6>
          1) Open the portal in a google chrome browser then tap the download
          icon located in the address bar
        </h6>
        <img
          style={{ margin: '48px 0px', maxWidth: '600px' }}
          src={BrowserInstall1}
          alt="BrowserInstall1"
        />
        <h6>
          2) Then tap "Add to Home Screen." or "Install" depending on your
          browser version.
        </h6>
        <img
          style={{ margin: '48px 0px', maxWidth: '500px' }}
          src={BrowserInstall2}
          alt="BrowserInstall2"
        />
        <div
          style={{ marginBottom: '120px', marginTop: '10px' }}
          className="buttons-center-container"
        >
          <ActionButton action={() => navigate('/')} label={'Accept'} />
        </div>
      </div>
    </div>
  );
};
