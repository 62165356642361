import Install1 from '../../../images/Install1.jpg';
import Install2 from '../../../images/Install2.png';
import Install3 from '../../../images/Install3.jpg';
import Install4 from '../../../images/Install4.jpg';
import { ActionButton } from '../../../components/common/Buttons/Buttons';
import './AppleDownload.scss';
import { useNavigate } from 'react-router-dom';

export function AppleDownload() {
  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className='row instructions-container'>
        <h1>Apple Download Process</h1>
        <h6>
          1) Open the portal in a Safari browser then tap on the share button
        </h6>
        <img
          className='install-process-step-image'
          src={Install1}
          alt='Install1'
        />
        <h6>
          2) Then scroll down and tap "Add to Home Screen." Enter the name for
          the app then tap add.
        </h6>
        <img
          className='install-process-step-image'
          src={Install2}
          alt='Install2'
        />

        <img
          className='install-process-step-image'
          src={Install3}
          alt='Install3'
        />
        <h6>3) The PWA will show up on your home screen.</h6>
        <img
          className='install-process-step-image'
          src={Install4}
          alt='Install5'
        />
        <div
          style={{ marginBottom: '120px' }}
          className='buttons-center-container'
        >
          <ActionButton action={() => navigate('/')} label={'Accept'} />
        </div>
      </div>
    </div>
  );
}
