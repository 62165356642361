import AndroidInstall1 from '../../../images/AndroidInstall1.jpg';
import AndroidInstall2 from '../../../images/AndroidInstall2.jpg';
import AndroidInstall3 from '../../../images/AndroidInstall3.jpg';
import AndroidInstall4 from '../../../images/AndroidInstall4.jpg';
import { ActionButton } from '../../../components/common/Buttons/Buttons';
import './AndroidDownload.scss';
import { useNavigate } from "react-router-dom";

export const AndroidDownload = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row instructions-container">
        <h1>Android Download Process</h1>
        <h6>
          1) Open the portal in a google chrome browser then tap the three-dot
          overflow menu in the top-right corner.
        </h6>
        <img
          className="install-process-step-image"
          src={AndroidInstall1}
          alt="AndroidInstall1"
        />
        <h6>
          2) Then tap "Add to Home Screen." or "Install" depending on your
          browser version.
        </h6>
        <img
          className="install-process-step-image"
          src={AndroidInstall2}
          alt="AndroidInstall2"
        />

        <img
          className="install-process-step-image"
          src={AndroidInstall3}
          alt="AndroidInstall3"
        />
        <h6>3) The PWA will show up on your home screen.</h6>
        <img
          className="install-process-step-image"
          src={AndroidInstall4}
          alt="AndroidInstall4"
        />
        <div
          style={{ marginBottom: "120px" }}
          className="buttons-center-container"
        >
          <ActionButton action={() => navigate("/")} label={"Accept"} />
        </div>
      </div>
    </div>
  );
};
