import { ActionButton, } from '../../common/Buttons/Buttons';
import card from '../../../images/card.svg';
import './MainPage.scss';



export const PortalOptionCard = (props) => {
  const { title, subtitle, selectAction, action } = props
  return <div onClick={() => selectAction(action)} className="portal-option-card">
    <img src={card} alt="card" />
    <h3 style={{ color: "#465b7a" }}>{title}</h3>
    <h6 style={{ color: '#666666' }}>{subtitle}</h6>
  </div>
}


export const WelcomePage = (props) => {
  const {
    step, nextStep, selectAction, action
  } = props;
  return (
    <>
      <div style={{ marginTop: "48px" }} className="flex justify-center">
        <div className="bg-white shadow-md py-4 rounded-md">
          <h3 className="text-center mt-2 mb-4 font-bold">
            Welcome to <br /> EXPRESS FUNDS
          </h3>
          <form className="form-container container">
            <div className="row">
              <p style={{ color: "gray" }}>Please select an option below to get started. </p>
              <div className="col-12">
              </div>
            </div>
            <PortalOptionCard selectAction={selectAction} action={"fund"} title={"Fund a Card"} subtitle={"Easily fund card"} />
          </form>
          <br />
          <div className="buttons-center-container">
            <ActionButton action={() => nextStep(step + 1)} label={'Continue'} disabled={action ? false : true} />
          </div>
        </div>
      </div>
    </>
  );
};



