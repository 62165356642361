import Auth from '@aws-amplify/auth';
import axios from 'axios';

export const getCognitoToken = async () => {
  const token = await Auth.currentSession();
  return token.getIdToken().getJwtToken();
};

export const instance = axios.create({
  headers: {
    'Content-type': 'application/json',
    // Authorization: `Bearer ${sessionStorage?.getItem('accessToken')}`,
  },
});
