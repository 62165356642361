import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { municipalityService } from '../../../services/municipalityService';
import { notify } from '../../../assets/helpers/toast';
import { cognitoAttibutes } from '../../../assets/enums/attributes';
import mocafi_logo from '../../../images/mocafi_logo.png';
import Auth from '@aws-amplify/auth';

export default function Header() {
  const history = useNavigate();
  const { signOut, user } = useAuthenticator((context) => [context.signOut]);
  const [navbar, setNavbar] = useState(false);
  const [municipality, setMunicipality] = useState('');


  function logOut() {
    signOut();
    history('/login');
  }

  const getMunicipalities = async () => {
    municipalityService
      .getMunicipalities()
      .then(async (response) => {
        if ([200, 201].includes(response.status)) {
          let municipalities = response?.data?.data.map(item => ({
            _id: item?._id,
            name: item?.municipality
          }));
          const user = await Auth.currentAuthenticatedUser();
          let municipality = user.attributes[cognitoAttibutes?.municipality];
          let [selectedMunicipality] = municipalities?.filter(
            (m) => m?._id === municipality
          );
          if (selectedMunicipality) {
            setMunicipality(selectedMunicipality);
          }
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };


  useEffect(() => {
    getMunicipalities();
  }, []);

  return (
    <nav className="w-full bg-mocablue shadow">
      <div className="justify-between px-4 align-middle lg:max-w-8xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-6 md:block">
            <div className="flex gap-2 items-center">
              <img className='w-12 h-12' src={mocafi_logo} alt="" />
              <h2 className="text-lg font-bold p-0 m-0 text-white">{municipality?.name}</h2>
            </div>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="white"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className={`md:block ${navbar ? 'block' : 'hidden'}`}>
            <button type="button" onClick={() => logOut()}>
              <span className='text-white'>Logout</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
